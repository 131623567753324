import Link from 'gatsby-link';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { AppContext } from '~/components/context/AppContext';
import { getFormattedCart, getUpdatedItems } from '~/utils/functions';
import CartItem from './CartItem';
import { v4 } from 'uuid';
import { useMutation, useQuery } from '@apollo/client';
import UPDATE_CART from '~/mutations/update-cart';
import GET_CART from '~/queries/get-cart';
import CLEAR_CART_MUTATION from '~/mutations/clear-cart';
import { LinkInner, UnderlineLink } from '~/components/elements/Link';
import { Online, Spinner } from '~/components/elements/Icon';

import jwtDecode from 'jwt-decode';
import { isEmpty } from 'lodash';

const CartItemsContainer = () => {
  const [cart, setCart] = useContext(AppContext);
  const [requestError, setRequestError] = useState(null);
  const [fetchedCart, setFetchedCart] = useState(false);

  const [session] = useState(() => {
    try {
      const session = typeof window !== 'undefined' ? localStorage.getItem('woo-session') : null;
      const jwtSession = session;
      const decoded = jwtDecode<{ data: { customer_id: string } }>(jwtSession);

      return decoded.data.customer_id;
    } catch (error) {
      console.error(error.message);
      return null;
    }
  });

  // Get Cart Data.
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      // console.warn( 'completed GET_CART', data );
      // Update cart in the localStorage.
      const updatedCart = getFormattedCart(data);
      localStorage.setItem('woo-next-cart', JSON.stringify(updatedCart));

      // Update cart data in React Context.
      setCart(updatedCart);
      setFetchedCart(true);
    },
  });

  // Update Cart Mutation.
  const [updateCart, { loading: updateCartProcessing }] = useMutation(UPDATE_CART, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      if (error) {
        setRequestError(error.graphQLErrors[0].message);
      }
    },
  });

  // Update Cart Mutation.
  const [clearCart, { loading: clearCartProcessing }] = useMutation(CLEAR_CART_MUTATION, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      if (error) {
        setRequestError(error.graphQLErrors[0].message);
      }
    },
  });

  /*
   * Handle remove product click.
   *
   * @param {Object} event event
   * @param {Integer} Product Id.
   *
   * @return {void}
   */
  const handleRemoveProductClick = (event, cartKey, products) => {
    event.stopPropagation();
    if (products.length) {
      // By passing the newQty to 0 in updateCart Mutation, it will remove the item.
      const newQty = 0;
      const updatedItems = getUpdatedItems(products, newQty, cartKey);

      updateCart({
        variables: {
          input: {
            clientMutationId: v4(),
            items: updatedItems,
          },
        },
      });
    }
  };

  // Clear the entire cart.
  const handleClearCart = (event) => {
    event.stopPropagation();

    if (clearCartProcessing) {
      return;
    }

    clearCart({
      variables: {
        input: {
          clientMutationId: v4(),
          all: true,
        },
      },
    });
  };

  return (
    <section className="section">
      {fetchedCart ? (
        <>
          {cart ? (
            <div className="container woo-next-cart-wrapper space-y-10">
              <div className="space-y-10 lg:space-y-0 lg:grid grid-cols-12 lg:gap-x-8 xl:gap-x-16">
                <div className="col-span-7">
                  <div className="space-y-7">
                    <h5 className="t-18 font-medium font-body pb-1.5">
                      Item summary {cart.products.length && `(${cart.products.length})`}
                    </h5>
                    <div>
                      {cart.products.length &&
                        cart.products.map((item) => (
                          <CartItem
                            key={item.productId}
                            item={item}
                            updateCartProcessing={updateCartProcessing}
                            products={cart.products}
                            handleRemoveProductClick={handleRemoveProductClick}
                            updateCart={updateCart}
                          />
                        ))}
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="font-medium max-w-[80%]">
                        Please Note: We never ship your hearing aids directly to you. They will be shipped first to your
                        Yes Hearing local specialist prior to your home visit
                      </p>
                      <div className="clear-cart">
                        <button
                          className="text-underline "
                          onClick={(event) => handleClearCart(event)}
                          disabled={clearCartProcessing}
                        >
                          <UnderlineLink link={{ title: 'Clear Cart' }} />
                        </button>
                        {clearCartProcessing ? <p>Clearing...</p> : ''}
                      </div>
                    </div>
                    {requestError && (
                      <div className="woo-next-cart-table-row row">
                        {requestError ? (
                          <div className="mt-5 row woo-next-cart-total-container text-error font-medium t-18">
                            {' '}
                            {requestError}{' '}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                    <div
                      className="trustpilot-widget w-[189px] sm:w-[420px] sm:-ml-2.5"
                      data-locale="en-US"
                      data-template-id="5419b6ffb0d04a076446a9af"
                      data-businessunit-id="5fc67a080737a300019b2dd4"
                      data-style-height="20px"
                      data-style-width="100%"
                      data-theme="light"
                      data-text-color="#0B3F6F"
                    >
                      <a href="https://www.trustpilot.com/review/yeshearing.com" target="_blank" rel="noopener">
                        <span className="hidden">Trustpilot</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-span-5">
                  <div className="space-y-7">
                    <h5 className="t-30">Order summary</h5>
                    <div className="border border-[#EEEEEE] rounded-[8px] p-6 space-y-5">
                      <div className="flex items-center justify-between">
                        <div className="font-semibold">Total:</div>
                        <div className="font-bold">
                          {'string' !== typeof cart.totalProductsPrice
                            ? cart.totalProductsPrice.toFixed(2)
                            : cart.totalProductsPrice}
                        </div>
                      </div>
                      <p>Taxes and shipping calculated at next step</p>
                    </div>
                    <a href={`${process.env.GATSBY_WP_URL}/checkout?session_id=${session}`} className="block">
                      <button className="button button-primary w-full">
                        <span>Checkout</span>
                        <span aria-hidden>Checkout</span>
                      </button>
                    </a>

                    <div>
                      <div className="font-semibold">Looking to use a discount code?</div>
                      No worries! You'll be able to use it during Checkout.
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="">
                  <div className="space-y-7">
                    <h4 className="t-25">Have a question? Contact us.</h4>
                    <div className="flex flex-col md:flex-row items-start space-y-5 md:space-y-0 md:space-x-12">
                      <div className="space-y-4">
                        <div className="space-y-2">
                          <h5 className="t-18">Live chat</h5>
                          <div className="text-green space-x-2 flex items-center">
                            <Online />
                            <div>Online Now</div>
                          </div>
                        </div>
                        <LinkInner link={{ url: '/book-a-consultation', title: "Let's chat" }} />
                      </div>
                      <div className="space-y-4">
                        <div className="space-y-2">
                          <h5 className="t-18">Email Us</h5>
                          <a href="mailto:contact@yeshearing.com" className="text-green block">
                            contact@yeshearing.com
                          </a>
                        </div>
                        <LinkInner link={{ url: 'mailto:contact@yeshearing.com', title: "Let's email" }} />
                      </div>
                      <div className="space-y-4">
                        <div className="space-y-2">
                          <h5 className="t-18">Call Us</h5>
                          <a href="tel:877-220-6389" className="text-green block mktst_ph_qhzhaVIYmmKwpq2beA8w">
                            877-220-6389
                          </a>
                        </div>
                        <div className="t-black-65 t-15">9am-5pm | 5 days a week</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container  text-center space-y-10 py-32">
              <h2 className="t-45">No items in the cart</h2>
              <LinkInner link={{ url: '/products', title: 'Add New Products' }} type="button" />
            </div>
          )}
        </>
      ) : (
        <div className="container py-40 flex items-center justify-center text-navy">
          <div className="w-16 fw-svg">
            <Spinner />
          </div>
        </div>
      )}
    </section>
  );
};

export default CartItemsContainer;
